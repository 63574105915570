<template>
  <div>
    <nav>
      <!-- NAVBAR-->
      <Navbar
        :navbar="navbar"
        @help="help"
      ></Navbar>
    </nav>
    <main :class="{
        'ml-10': $vuetify.display.mdAndUp,
        'ml-n4': $vuetify.display.smAndDown,
        'me-10': $vuetify.display.mdAndUp,
        'me-n4': $vuetify.display.smAndDown,
      }">
      <v-container fluid>
        <v-card
          flat
          class="mx-auto"
          max-width="660"
        >
          <v-row class="ml-10 mt-4 mb-0">
            <v-col cols="2"></v-col>
            <v-col cols="6">
              <v-row>
                <v-img
                  src="@/assets/logo/logo_blue21.png"
                  max-width="240"
                  max-height="60"
                ></v-img>
              </v-row>
            </v-col>
            <v-col cols="4"></v-col>
          </v-row>

          <v-row>
            <v-col cols="7">
              <v-row>
                <h1 class="text-h5 titleH1 ml-6 mt-4">{{ $t("menu.Main Menu") }}</h1>
                <v-btn
                  icon
                  color="primary"
                  variant="text"
                  @click="titleHelp = !titleHelp"
                >
                  <v-icon
                    class="mb-0"
                    size="16px"
                  >mdi-help-circle-outline</v-icon>
                </v-btn>
              </v-row>
            </v-col>

            <v-col
              cols="5"
              v-if="$store.state.user.profile != 'Deliver' && $store.state.user.profile != 'Self-Service'"
            >
              <v-img
                id="imgLogoSrc"
                ref="imgLogoSrc"
                name="imgLogoSrc"
                class="mt-4 me-4"
                v-if="src"
                :src="src"
                max-width="110"
                max-height="110"
                @load="getImgSize()"
              ></v-img>
            </v-col>
          </v-row>

          <v-alert
            style="line-height: 1.5"
            v-model="titleHelp"
            class="mt-3"
            type="info"
            variant="tonal"
            transition="slide-y-reverse-transition"
            closable
          >{{ $t("menu.titleHelp") }}</v-alert>

          <!-- <v-divider class="mt-2 mb-2"></v-divider> -->
          <!-- dummy -> test -->
          <!-- <v-btn
            v-if="$store.state.user.email == 'marco@gestao21.com.br' || $store.state.user.email == 'marcoadefilippis@gmail.com'"
            class="ml-16 mt-2 mb-2"
            @click="goRouter('ImportRecordsFinancial')"
          >
            Importar
          </v-btn> -->

          <v-card
            flat
            v-if="
            $store.state.user.profile
            !='Deliver'
            &&
            $store.state.user.profile
            !='Self-Service'"
          >
            <v-row>
              <v-col cols="7">
                <!-- <v-navigation-drawer
                  permanent
                  width="100%"
                > -->
                <v-list color="primary">
                  <v-list-item
                    v-for="item in menuMain"
                    :key="item.module"
                    link
                    @click="goMenuModule(item)"
                  >
                    <template v-slot:prepend>
                      <v-icon :icon="item.icon"></v-icon>
                    </template>
                    <v-list-item-title class="text-h6 mt-1"> {{$t('common.' + item.module)}} </v-list-item-title>
                  </v-list-item>

                </v-list>
                <!-- </v-navigation-drawer> -->
              </v-col>

              <!-- establishment -->
              <v-col
                cols="5"
                v-if="establishment"
              >
                <v-img
                  class="mt-12"
                  :src="getImgUrl()"
                  height="300"
                  width="200"
                ></v-img>
                <span class="ml-4 mt-0 text-center text-h5 font-weight-bold ">{{ $t("enterprise." + establishmentName)
                 }}</span>
              </v-col>
            </v-row>
          </v-card>

          <v-row v-if="$store.state.user.profile != 'Deliver' && $store.state.user.profile != 'Self-Service'">
            <v-btn
              variant="text"
              small
              class="ml-4 mt-2 mb-2"
              @click="goUrlHelp()"
            >
              <v-icon size="22px">mdi-help-circle-outline</v-icon>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ $t("help.Help Site") }}
            </v-btn>
          </v-row>

          <h2
            v-if="userName"
            class="ml-2 mt-16 text-h5 text-primary"
          >
            {{ $t("user.Hello ") }}
            {{ userName }} !!!
          </h2>

          <!-- snackbar -->
          <v-snackbar
            v-model="snackbar.show"
            location="top"
            :color="snackbar.color"
            :timeout="snackbar.timeout"
          >{{ snackbar.text }}</v-snackbar>
        </v-card>

        <br />
      </v-container>
    </main>
  </div>
</template>
<script>
import Navbar from "@/app/2_common/components/page/Navbar.vue";
//
import { clearSessionStorage, toDataURL } from "@/helpers/helpersFunction.js";
import { firstUpperCase, helpLink } from "@/helpers/helpers.js";

//
import { api } from "@/services.js";

export default {
  name: "Menu",
  components: {
    Navbar,
  },

  data() {
    return {
      establishment: "",
      establishmentName: "",
      //
      menuMain: [
        { module: "Company", icon: "mdi-home-city-outline", route: "Company" },
        { module: "Stock", icon: "mdi-package-variant-closed", route: "Stock" },
      ],
      //
      module: null,
      src: null,
      logoBase64: "",
      userName: null,
      //
      titleHelp: false,

      enterpriseCounter: "",
      dateDatabase: null,
      //
      goBackOk: false,
      // Props Navbar
      navbar: {
        menu: [
          {
            text: "Help",
            icon: "mdi-help-circle-outline",
            action: "help",
            route: "",
          },
        ],
      },
      // snackbar
      snackbar: {
        show: false,
        color: "",
        text: "",
        timeout: 3000,
      },
    };
  },
  methods: {
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  JS     /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    goRouter(route) {
      // goRouter --> store.state.user.email == "marco@gestao21.com.br"
      sessionStorage.pageId = "payable";
      // sessionStorage.pageOrigin = "footer";
      // sessionStorage.routerOption = "footer";
      //
      this.$router.push({
        name: route,
      });
    },

    goMenuModule(item) {
      sessionStorage.module = item.route;
      this.$router.push({ name: "MenuModule" });

      // params --> not working vue-router v4
      // https://github.com/vuejs/router/blob/main/packages/router/CHANGELOG.md#414-2022-08-22
      // this.$router.push({ name: "MenuModule", params: { module: item.route } });
    },

    getImgSize() {
      const { naturalHeight, naturalWidth } = this.$refs.imgLogoSrc.image;
      // console.log(naturalHeight, naturalWidth);
      let ratio = (naturalWidth / naturalHeight).toFixed(1);
      if (ratio > 2.5) ratio = 2.5;
      if (ratio < 1) ratio = 1;
      sessionStorage.logoImgRatio = ratio; // used in createPDF
    },
    /////////////
    getImgUrl() {
      try {
        return require("@/assets/establishment/" + this.establishment + ".svg");
      } catch (e) {
        // console.log("sorry, file not found");
        return null;
      }
    },

    ////////////////////
    async later(delay) {
      return new Promise(function (resolve) {
        setTimeout(resolve, delay);
      });
    },

    //////////////////////
    checkEstablishment() {
      if (!this.$store.state.user.establishment) return;
      // establishment
      if (this.$store.state.user.establishment.restaurant) {
        this.establishment = "restaurant";
      } else if (this.$store.state.user.establishment.bakery) {
        this.establishment = "bakery";
      } else if (this.$store.state.user.establishment.pizzeria) {
        this.establishment = "pizzeria";
      } else if (this.$store.state.user.establishment.hamburgueria) {
        this.establishment = "hamburgueria";
      } else if (this.$store.state.user.establishment.snackBar) {
        this.establishment = "snackBar";
      } else if (this.$store.state.user.establishment.coffeeShop) {
        this.establishment = "coffeeShop";
      } else if (this.$store.state.user.establishment.bar) {
        this.establishment = "bar";
      } else if (this.$store.state.user.establishment.cellar) {
        this.establishment = "cellar";
      } else if (this.$store.state.user.establishment.nightclub) {
        this.establishment = "nightclub";
      } else if (this.$store.state.user.establishment.petShop) {
        this.establishment = "petShop";
      } else if (this.$store.state.user.establishment.autoParts) {
        this.establishment = "autoParts";
      } else if (this.$store.state.user.establishment.electricMaterial) {
        this.establishment = "electricMaterial";
      } else if (this.$store.state.user.establishment.others) {
        this.establishment = "";
      }
      this.establishmentName = firstUpperCase(this.establishment);
    },

    /////////////
    goUrlHelp() {
      let link = "https://ajuda.blue21.com.br/";
      window.open(link, "_blank");
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /// NAVBAR //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////
    help() {
      // console.log("this.$t = " + this.$t);
      let title = this.$t("help.Main Menu");
      helpLink(this.$t, title, "Menu", "");
    },

    goBack() {
      this.snackbar.show = false;
      this.goBackOk = true;
      this.$router.push({ name: "login" });
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /// MESSAGE //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    snackbarTimeout(timeout) {
      this.snackbar.show = true;
      this.snackbar.timeout = timeout;
      setTimeout(() => {
        this.goBack();
      }, this.snackbar.timeout);
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  API  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  COUNTER   //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////
    async countEnterprise() {
      // console.log("enterpriseCounter=" + this.$store.state.enterpriseCounter);
      if (
        this.$store.state.enterpriseCounter &&
        this.$store.state.enterpriseCounter > 0
      ) {
        this.enterpriseCounter = this.$store.state.enterpriseCounter;
        this.checkEstablishment();
        return this.enterpriseCounter;
      }
      if (this.$store.state.user.profile == "Deliver") {
        this.enterpriseCounter = 1;
        return this.enterpriseCounter;
      }
      //
      return api
        .get(`/enterprise/count`)
        .then((response) => {
          // console.log("0 - response.data = " + JSON.stringify(response.data));
          this.enterpriseCounter = response.data.enterprise_counter;
          this.dateDatabase = response.data.date_database;
          //
          this.$store.commit(
            "UPDATE_ENTERPRISE_COUNTER",
            this.enterpriseCounter
          );

          if (this.enterpriseCounter == 0) {
            this.$router.push({ name: "enterprise" });
          }
          //
          if (!this.dateDatabase && this.enterpriseCounter > 0) {
            window.scrollTo(0, 0);
            this.snackbar.color = "error";
            this.snackbar.text = this.$t("menu.msgErrorDB");
            this.snackbarTimeout(5000);
          }

          if (this.enterpriseCounter > 0) {
            this.checkEstablishment();
            // used in login
            localStorage.setItem(
              "counterAccount",
              JSON.stringify(this.enterpriseCounter)
            );
          }
          //
          return this.enterpriseCounter;
          //
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$router.push({ name: "login" });
          } else {
            // snackbar
            // window.scrollTo(0, 0);
            this.snackbar.color = "error";
            this.snackbar.text = err + " => countEnterprise";
            this.snackbar.show = true;
            //
          }
        });
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  insertCustomerUserDeliver  /////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    insertCustomerUserDeliver() {
      //
      return api
        .post("/customer/insertUserDeliver/", this.$store.state.user)
        .then((response) => {
          // console.log(response.data);
          if (String(response.data).match(/Attention:/)) {
            // snackbar
            // window.scrollTo(0, 0);
            this.snackbar.color = "warning";
            this.snackbar.text = this.$t("message." + response.data);
            this.snackbar.show = true;
            //
          } else {
            // let customerId = 0;
            // if (Number.isInteger(response.data)) customerId = response.data;
            // return customerId;
            // Not opening the store
            this.$router.go(-1);
          }
        })
        .catch((err) => {
          // snackbar
          window.scrollTo(0, 0);
          this.snackbar.color = "error";
          this.snackbar.text = err + " => insertCustomerUserDeliver";
          this.snackbar.show = true;
          this.snackbarTimeout(3000);
        });
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  synchronizeCustomerAddress /////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    synchronizeCustomerAddress(customerId) {
      // For Delivery -> Synchronizes Client Addresses with all the Addresses of a User - changes and adds those that are not yet in the client base.
      if (sessionStorage.synchronizeCustomerAddress == "yes") return;
      //
      return api
        .put(
          `/customerAddress/synchronizeCustomerAddress/?customerId=${customerId}`
        )
        .then((response) => {
          // console.log(response.data);
          if (String(response.data).match(/Attention:/)) {
            // snackbar
            // window.scrollTo(0, 0);
            this.snackbar.color = "warning";
            this.snackbar.text = this.$t("message." + response.data);
            this.snackbar.show = true;
            //
          } else {
            sessionStorage.synchronizeCustomerAddress = "yes";
          }
        })
        .catch((err) => {
          // snackbar
          window.scrollTo(0, 0);
          this.snackbar.color = "error";
          this.snackbar.text = err + " => synchronizeCustomerAddress";
          this.snackbar.show = true;
          this.snackbarTimeout(3000);
        });
    },

    ///////////////
    storeAccess() {
      this.$store.commit("UPDATE_CUSTOMER_REDUCED_REGISTRATION", "");

      // module company
      this.$store.commit("UPDATE_ACCESS_VIEW", false);
      this.$store.commit("UPDATE_ACCESS_EDIT", false);

      // module stock
      //////////////////////////////////////////////////
      let accessId = 412; // Product Supplier
      this.access = this.$store.state.user.access.find(
        (obj) => obj.accessId === accessId
      );
      this.$store.commit(
        "UPDATE_ACCESS_VIEW_PRODUCT_SUPPLIER",
        this.access.view
      );
      //////////////////////////////////////////////////
      accessId = 442; // Service Supplier
      this.access = this.$store.state.user.access.find(
        (obj) => obj.accessId === accessId
      );
      this.$store.commit(
        "UPDATE_ACCESS_VIEW_SERVICE_SUPPLIER",
        this.access.view
      );

      //////////////////////////////////////////////////
      accessId = 414; // Product Price Sale
      this.access = this.$store.state.user.access.find(
        (obj) => obj.accessId === accessId
      );
      // console.log("this.access = " + JSON.stringify(this.access));

      this.$store.commit(
        "UPDATE_ACCESS_EDIT_PRODUCT_PRICE_SALE",
        this.access.edit
      );

      //////////////////////////////////////////////////
      accessId = 444; // Service Price Sale
      this.access = this.$store.state.user.access.find(
        (obj) => obj.accessId === accessId
      );
      this.$store.commit(
        "UPDATE_ACCESS_EDIT_SERVICE_PRICE_SALE",
        this.access.edit
      );
    },

    ////////////////////
    async routerUserEntity() {
      // console.log("state.user= ", JSON.stringify(this.$store.state.user));
      // entity == "supplier"
      if (this.$store.state.user.entity == "supplier") {
        if (this.$store.state.user.profile == "Representative") {
          sessionStorage.module = "Commercial";
          this.$router.push({
            name: "MenuModule",
            // params: {
            //   module: "Commercial",
            // },
          });
          return;
        } else if (this.$store.state.user.profile == "Driver") {
          sessionStorage.module = "Delivery";
          this.$router.push({
            name: "MenuModule",
            // params: {
            //   module: "Delivery",
            // },
          });
          return;
        } else if (this.$store.state.user.profile == "Waiter") {
          sessionStorage.module = "Consumer";
          this.$router.push({
            name: "MenuModule",
            // params: {
            //   module: "Consumer",
            // },
          });
          return;
        } else {
          sessionStorage.module = "Purchase";
          this.$router.push({
            name: "MenuModule",
            // params: {
            //   module: "Purchase",
            // },
          });
        }
      } else if (this.$store.state.user.entity == "customer") {
        sessionStorage.module = "Commercial";
        this.$router.push({
          name: "MenuModule",
          // params: {
          //   module: "Commercial",
          // },
        });
      } else if (this.$store.state.user.profile == "Self-Service") {
        sessionStorage.module = "Consumer";
        this.$router.push({
          name: "MenuModule",
          // params: {
          //   module: "Consumer",
          // },
        });
      } else if (this.$store.state.user.profile == "Deliver") {
        var customerId = this.$store.state.user.customerId;
        //
        if (!customerId) {
          if (!this.$store.state.user.userAddress.address) {
            this.$router.push({ name: "login" });
            return;
          }
          customerId = await this.insertCustomerUserDeliver();
        }
        //
        if (customerId) {
          this.synchronizeCustomerAddress(customerId);
          //
          let delay = await this.later(1000); // waits to load parameters
          //
          sessionStorage.module = "Consumer";
          this.$router.push({
            name: "MenuModule",
            // params: {
            //   module: "Consumer",
            // },
          });
        }
      }
    },

    ////////////////
    mountModule() {
      let i = 1; // stock

      if (this.$store.state.user.purchaseModule) {
        i = i + 1;
        this.menuMain.splice(i, 0, {
          module: "Purchase",
          icon: "mdi-truck-outline",
          route: "Purchase",
        });
      }
      //
      if (this.$store.state.user.consumerModule) {
        i = i + 1;
        this.menuMain.splice(i, 0, {
          module: "Consumer",
          icon: "mdi-face-man",
          route: "Consumer",
        });
      }
      //
      if (this.$store.state.user.commercialModule) {
        i = i + 1;
        this.menuMain.splice(i, 0, {
          module: "Commercial",
          icon: "mdi-store",
          route: "Commercial",
        });
      }

      // if (
      //   this.$store.state.user.email == "marco@gestao21.com.br" ||
      //   this.$store.state.user.email == "marcoadefilippis@gmail.com"

      if (this.$store.state.user.financialModule) {
        i = i + 1;
        this.menuMain.splice(i, 0, {
          module: "Financial",
          icon: "mdi-finance",
          route: "Financial",
        });
      }

      // { module: "Delivery", icon: "airport_shuttle", route: "Delivery" },
      if (this.$store.state.user.deliveryModule) {
        i = i + 1;
        this.menuMain.splice(i, 0, {
          module: "Deliveries",
          icon: "mdi-moped-outline",
          route: "Delivery",
        });
      }

      // { module: "Accesses", icon: "mdi-account-outline", route: "Access" },
      i = i + 1;
      this.menuMain.splice(i, 0, {
        module: "Accesses",
        icon: "mdi-account-outline",
        route: "Access",
      });
    },
  },

  /////////////////
  async created() {
    if (!this.$store.state.login) {
      this.$router.push({ name: "login" });
    }
    if (!sessionStorage.module) this.userName = this.$store.state.user.userName;
    this.module = "";
    //
    // console.log("Menu1 sessionStorage.locale = " + sessionStorage.locale);

    if (!sessionStorage.locale) {
      sessionStorage.locale = window.navigator.language.toLowerCase();
    }
    //
    this.storeAccess();
    //
    this.routerUserEntity();
    //
    this.mountModule();
    //
    // console.log("Menu2 sessionStorage.locale = " + sessionStorage.locale);

    try {
      let countEnterprise = await this.countEnterprise();
      // console.log("1 -  countEnterprise = " + countEnterprise);
      if (countEnterprise > 0) {
        ////////////////////////////////////////////////////////////
        // logo src /////////////////////////////////////////////////
        if (this.$store.state.user.src) {
          this.src = this.$store.state.user.src;
          toDataURL(this.src, function (dataUrl) {
            // console.log(dataUrl);
            sessionStorage.logoBase64 = dataUrl;
            // vm.logoBase64 = dataUrl;
          });
        }
        ////////////////////////////////////////////////////////////
      }
    } catch (e) {
      window.scrollTo(0, 0);
      this.snackbar.color = "error";
      this.snackbar.text = e + " => created Menu";
      this.snackbar.show = true;
    }
  },
  /////////
  mounted() {
    clearSessionStorage();
  },
};
</script>

