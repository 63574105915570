<template>
  <v-form
    v-model="address.formValid"
    ref="form"
  >

    <!-- address.formValid = {{address.formValid}} -->
    <v-card
      flat
      :disabled="$store.state.view"
    >
      <v-divider class="mt-4"></v-divider>

      <!-- Address -->
      <h2 class="titleH2">{{ $t("address.Address") }}</h2>
      <v-card
        flat
        v-if="entityId"
      >
        <v-row justify="end">
          <!-- "Foreigner" -->
          <v-switch
            :label="$t('address.Foreigner')"
            v-model="address.foreignerAd"
            class="me-1"
            id="foreignerAd"
            name="foreignerAd"
          ></v-switch>
          <v-btn
            icon
            color="primary"
            variant="text"
            class="ml-1 me-0 mt-4"
            @click="foreignerAdHelp = !foreignerAdHelp"
          >
            <v-icon
              size="16px"
              class="mb-3"
            >mdi-help-circle-outline</v-icon>
          </v-btn>
        </v-row>
        <v-alert
          style="line-height: 1.5"
          v-model="foreignerAdHelp"
          type="info"
          border="start"
          variant="tonal"
          transition="slide-y-reverse-transition"
          closable
        >{{ $t("entity.foreignerAdHelp") }}</v-alert>
      </v-card>

      <v-alert
        style="line-height: 1.5"
        v-model="alertNewGeolocation"
        class="ml-10 me-4 mt-4"
        type="warning"
        border="start"
        variant="tonal"
        transition="slide-y-reverse-transition"
        closable
      >{{ $t("address.alertNewGeolocation") }}</v-alert>

      <!-- Country -->
      <!-- countries = {{countries}} -->
      <v-autocomplete
        color="primary"
        v-if="address.foreignerAd || entityForeigner"
        :label="$t('address.Country *')"
        v-model="address.countryCode"
        :items="countries"
        item-title="name"
        item-value="code"
        class="ml-10 me-4"
        id="countryCode"
        name="countryCode"
        :rules="[rules.countryCodeRules]"
        clearable
        @update:modelValue="address.countryCode = $event ? $event : ''"
        variant="underlined"
      ></v-autocomplete>

      <!-- myCompanyCountry = {{ myCompanyCountry }} -->
      <br>
      <v-card
        flat
        v-if="
          myCompanyCountry == 'BR' &&
          !address.postalCode &&
          !address.foreignerAd &&
          !entityForeigner
        "
      >
        <v-row>
          <v-col cols="6">
            <!-- CEP -->
            <v-text-field
              color="primary"
              variant="underlined"
              :label="cepLabel"
              placeholder="99999999"
              v-model="address.cepConsult"
              class="ml-10"
              append-outer-icon="search"
              id="cepConsult"
              name="cepConsult"
              :loading="loadingCep"
              @keyup="completeCep"
              :rules="[rules.cepConsultRules]"
              validate-on="blur"
            ></v-text-field>
          </v-col>
          <v-btn
            icon
            color="primary"
            variant="text"
            class="ml-0 mt-4"
            @click="cepConsultHelp = !cepConsultHelp"
          >
            <v-icon
              size="16px"
              class="mb-3"
            >mdi-help-circle-outline</v-icon>
          </v-btn>
        </v-row>
        <v-alert
          style="line-height: 1.5"
          v-model="cepConsultHelp"
          type="info"
          border="start"
          variant="tonal"
          transition="slide-y-reverse-transition"
          closable
        >{{ $t("address.cepConsultHelp") }}</v-alert>
      </v-card>
      <!-- address.countryCode = {{ address.countryCode }} -->
      <!-- "Endereço" -->
      <v-text-field
        color="primary"
        variant="underlined"
        v-if="
          address.cepConsult ||
          address.postalCode ||
          address.foreignerAd ||
          entityForeigner ||
          myCompanyCountry != 'BR'
        "
        :label="$t('address.Address *')"
        v-model="address.address"
        class="me-4"
        id="address"
        name="address"
        maxlength="99"
        prepend-icon="mdi-map-marker"
        :readonly="!address.countryCode && myCompanyCountry == 'BR'"
        :rules="[rules.addressRules]"
        @keyup="address.update = true"
      ></v-text-field>
      <!-- @keyup="validateNumber()" -->
      <br>
      <!-- -entity={{ entity }}
      -myCompanyCountry={{ myCompanyCountry }}
      -address.countryCode={{ address.countryCode }} -->
      <br>
      <br>
      <v-card
        flat
        v-if="(address.cepConsult || address.postalCode) && ((!address.countryCode && myCompanyCountry == 'BR' || address.countryCode == 'BR') || (entity == 'Enterprise' &&  myCompanyCountry == 'BR') || entity == 'deliver' || entity == 'login') "
      >
        <v-row>
          <v-col cols="6">
            <!-- "Número" -->
            <v-text-field
              color="primary"
              variant="underlined"
              :label="$t('address.Number') + ' *'"
              v-model="address.number"
              class="ml-10 me-4"
              ref="number"
              id="number"
              name="number"
              maxlength="10"
              @keyup="validateNumber(); address.update = true"
              :rules="[rules.numberRules]"
              autocomplete="null"
              @blur="getCoordinatesAddress()"
            ></v-text-field>
          </v-col>
          <v-btn
            icon
            color="primary"
            variant="text"
            @click="numberHelp = !numberHelp"
          >
            <v-icon
              size="16px"
              class="mb-3"
            >mdi-help-circle-outline</v-icon>
          </v-btn>
        </v-row>
        <v-alert
          style="line-height: 1.5"
          v-model="numberHelp"
          type="info"
          border="start"
          variant="tonal"
          transition="slide-y-reverse-transition"
          closable
        >{{ $t("address.numberHelp") }}</v-alert>
      </v-card>

      <!-- "Complemento" -->
      <v-text-field
        color="primary"
        variant="underlined"
        v-if="
          address.cepConsult ||
          address.postalCode ||
          address.foreignerAd ||
          entityForeigner ||
          myCompanyCountry != 'BR'
        "
        :label="$t('address.Complement')"
        v-model="address.complement"
        id="complement"
        name="complement"
        maxlength="99"
        class="ml-10 me-4"
        @keyup="address.update = true"
        autocomplete="null"
      ></v-text-field>

      <!-- "Bairro" -->
      <v-text-field
        color="primary"
        variant="underlined"
        v-if="
          address.cepConsult ||
          address.postalCode ||
          address.foreignerAd ||
          entityForeigner ||
          myCompanyCountry != 'BR'
        "
        :label="$t('address.Neighborhood')"
        v-model="address.neighborhood"
        class="ml-10 me-4"
        id="neighborhood"
        name="neighborhood"
        maxlength="99"
        :readonly="!address.countryCode && myCompanyCountry == 'BR'"
        @keyup="address.update = true"
        autocomplete="null"
      ></v-text-field>

      <!-- "Cidade" -->
      <v-text-field
        color="primary"
        variant="underlined"
        v-if="
          address.cepConsult ||
          address.postalCode ||
          address.foreignerAd ||
          entityForeigner ||
          myCompanyCountry != 'BR'
        "
        :label="$t('address.City')"
        v-model="address.city"
        class="ml-10 me-4"
        id="city"
        name="city"
        maxlength="99"
        :readonly="!address.countryCode && myCompanyCountry == 'BR'"
        @keyup="address.update = true"
        autocomplete="null"
        @blur="address.foreignerAd && !address.addressId ? getCoordinatesAddress() : {}"
      ></v-text-field>

      <v-row>
        <v-col cols="6">
          <!-- "Estado" -->
          <v-text-field
            color="primary"
            variant="underlined"
            v-if="
              address.cepConsult ||
              address.postalCode ||
              address.foreignerAd ||
              entityForeigner ||
              myCompanyCountry != 'BR'
            "
            :label="$t('address.State')"
            v-model="address.state"
            class="ml-10 me-4"
            id="state"
            name="state"
            maxlength="99"
            :readonly="!address.countryCode && myCompanyCountry == 'BR'"
            @update:modelValue="storeAddressState"
            @keyup="address.update = true"
            autocomplete="null"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <!-- "Cep" -->
          <v-text-field
            color="primary"
            variant="underlined"
            v-if="address.address"
            :label="$t('address.Zip Code *')"
            v-model="address.postalCode"
            class="ml-10 me-4"
            id="postalCode"
            name="postalCode"
            maxlength="20"
            :readonly="!address.countryCode && myCompanyCountry == 'BR'"
            clearable
            @click:clear="clearAddrees()"
            @update:modelValue="address.postalCode = $event ? $event : ''"
            @keyup="address.update = true"
            :rules="[rules.postalCodeRules]"
            validate-on="blur"
            autocomplete="null"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- //////////////////////////////////////////////////////////////////////////////////////////////////// -->
      <!-- Address Type -->
      <v-card
        flat
        v-if="address.postalCode"
      >
        <v-card
          flat
          v-if="$store.state.personType === 'Company'"
        >
          <v-btn-toggle
            v-model="address.addressType"
            class="ml-6"
            color="deep-purple-lighten-3"
            group
          >
            <!--  -->
            <!-- Main -->
            <v-btn
              value="Main"
              @click="address.update = true"
            >
              {{ $t("address.Main") }}
              <v-icon
                class="ml-1"
                size="18"
              >mdi-home-outline</v-icon>
            </v-btn>
            <!-- "Entrega" -->
            <v-btn
              value="Delivery"
              @click="address.update = true"
            >
              {{ $t("address.Delivery") }}
              <v-icon
                class="ml-1"
                size="18"
              >mdi-truck-outline</v-icon>
            </v-btn>
            <!-- "Cobrança" -->
            <v-btn
              value="Billing"
              @click="address.update = true"
            >
              {{ $t("address.Billing") }}
              <v-icon
                class="ml-1"
                size="18"
              >mdi-currency-usd</v-icon>
            </v-btn>

            <v-btn
              value="Others"
              @click="address.update = true"
              v-if="false"
            >
              {{ $t("address.Others") }}
              <v-icon
                class="ml-1"
                size="18"
              >mdi-map-marker-question</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-card>

        <v-card
          flat
          v-else
        >
          <v-btn-toggle
            v-model="address.addressType"
            class="ml-6"
            color="deep-purple-lighten-3"
            group
          >
            <v-btn
              value="Home"
              @click="address.update = true"
            >
              {{ $t("address.Home") }}
              <v-icon
                class="ml-1"
                size="18"
              >mdi-home-outline</v-icon>
            </v-btn>

            <v-btn
              value="Work"
              @click="address.update = true"
            >
              {{ $t("address.Work") }}
              <v-icon
                class="ml-1"
                size="18"
              >mdi-domain</v-icon>
            </v-btn>

            <v-btn
              value="Others"
              @click="address.update = true"
            >
              {{ $t("address.Others") }}
              <v-icon
                class="ml-1"
                size="18"
              >mdi-map-marker-question</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-card>
      </v-card>

      <!-- //////////////////////////////////////////////////////////////////////////////////////////////////// -->
      <!-- moreInfo -->
      <v-tooltip
        location="right"
        :text="$t('address.moreAddress')"
        v-if="address.postalCode && entity != 'deliver' && entity != 'login'"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            variant="text"
            class="ml-10 me-4 mb-4"
            icon
            @click="prefer.addressMoreInfo = !prefer.addressMoreInfo; updateSessionPrefer();"
          >
            <v-icon
              v-if="!prefer.addressMoreInfo && (!$store.state.view || $store.state.edit)"
              color="#24b47e"
            >mdi-plus</v-icon>
            <v-icon
              v-else
              color="#24b47e"
            >mdi-chevron-up</v-icon>
          </v-btn>
        </template>
      </v-tooltip>

      <!-- "Referência" -->
      <v-textarea
        color="primary"
        variant="underlined"
        v-if="(prefer.addressMoreInfo && address.postalCode) || $store.state.view"
        :label="$t('address.Reference')"
        v-model="address.reference"
        class="ml-10 me-4"
        id="reference"
        name="reference"
        maxlength="99"
        auto-grow
        rows="1"
        clearable
        @update:modelValue="address.reference = $event ? $event : ''"
        @keyup="address.update = true"
      ></v-textarea>

      <v-row>
        <v-col cols="6">
          <!-- "Região" -->
          <v-text-field
            color="primary"
            variant="underlined"
            v-if="(prefer.addressMoreInfo && address.postalCode && entity != 'deliver' && entity != 'login') || $store.state.view"
            :label="$t('address.Region')"
            v-model="address.region"
            class="ml-10 me-4"
            id="region"
            name="region"
            maxlength="99"
            @keyup="address.update = true"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Observation -->
      <v-textarea
        color="primary"
        variant="underlined"
        v-if="(prefer.addressMoreInfo && address.postalCode && entityId) || $store.state.view"
        :label="$t('common.Observation')"
        v-model="address.observationAd"
        class="ml-10 me-4"
        id="observationAd"
        name="observationAd"
        maxlength="2048"
        auto-grow
        rows="1"
        clearable
        @update:modelValue="address.observationAd = $event ? $event : ''"
        @keyup="address.update = true"
      ></v-textarea>
      <!-- access.delete = {{ access.delete }} -->
      <!--Inactive -->
      <v-row v-if="((prefer.addressMoreInfo || address.inactiveAd) && address.addressId) || $store.state.view">
        <v-spacer></v-spacer>
        <v-switch
          inset
          :label="$t('common.Inactive')"
          v-model="address.inactiveAd"
          :readonly="!access.delete"
          class="me-8 mb-4"
          id="inactiveAd"
          name="inactiveAd"
          maxlength="1024"
          color="orange"
          @update:modelValue="address.update = true"
        ></v-switch>
      </v-row>
    </v-card>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar.show"
      location="top"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >{{ snackbar.text }}</v-snackbar>

  </v-form>
</template>

<script>
//
import { getCep } from "@/services.js";
import { countriesList } from "@/json/countries.js";
import { validatePostalCode } from "@/helpers/helpers.js";

// google-maps-api
import { Loader } from "@googlemaps/js-api-loader";
const loader = new Loader({
  apiKey: "AIzaSyA7ZzA9d5lDOjd48vCp3rS_IdPhKn_9SDw",
});
//
export default {
  name: "Address",
  props: {
    address: {},
  },
  ////////
  data() {
    return {
      alertNewGeolocation: false,
      addressNumberFirst: false,
      addressComplete: "",
      numberOld: "",
      //
      cepLabel: "CEP",
      myCompanyCountry: "",
      countries: [],
      //
      entityForeigner: false,
      loadingCep: false,
      entity: "",
      // Help
      foreignerAdHelp: false,
      numberHelp: false,
      cepConsultHelp: false,
      // standard
      access: {},
      // Setting
      pageId: "",
      groupUser: "",
      // Prefer
      prefer: {},

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      ///  PROPS Dialog MainButton  Navbar  ///////////////////////////////////////////////////////////////////////////////////////////
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // snackbar
      snackbar: {
        show: false,
        color: "",
        text: "",
        timeout: 3000,
      },

      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      ///  RULES     //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      rules: {
        // postalCode
        cepConsultRules: (value) => {
          // console.log("value = " + value);
          if (!value && !this.entityId) return true;
          if (!value) return this.$t("rules.Required Field");
          //
          var valido = /^(?:\.|-|[0-9])*$/.test(value); // . ou -
          if (!valido) return this.$t("rules.Zip code enter only numbers");
          if (!this.address.address) return this.$t("rules.Invalid Zip code");
          return true;
        },
        countryCodeRules: (value) => {
          if (!value && (this.address.foreignerAd || this.entityForeigner)) {
            return this.$t("rules.Required Field");
          } else {
            return true;
          }
        },
        addressRules: (value) => {
          if (
            !value &&
            (this.entityId || this.address.foreignerAd || this.entityForeigner)
          ) {
            return this.$t("rules.Required Field");
          } else {
            return true;
          }
        },
        numberRules: (value) => {
          if (this.myCompanyCountry != "BR") return true;
          if (!value && (this.address.address || this.entityId)) {
            return this.$t("rules.Required Field");
          } else {
            return true;
          }
        },
        postalCodeRules: (value) => {
          if (this.address.cepConsult) return true;
          if (!value && (this.address.foreignerAd || this.entityForeigner))
            return true;
          if (!value && (this.address.address || this.entityId))
            return this.$t("rules.Required Field");
          //
          // console.log("postalCode = " + value);

          let result = validatePostalCode(value, this.address.countryCode);
          if (result == "") return true;
          //
          return " " + this.$t("rules." + result);
        },
      },
    };
  },
  //////////
  methods: {
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  JS     /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    completeCep() {
      this.loadingCep = false;
      this.cepConsultHelp = false;
      const postalCode = this.address.cepConsult.replace(/\D/g, "");
      if (postalCode.length === 8) {
        this.loadingCep = true;
        getCep(postalCode).then((response) => {
          // console.log(json);
          if (!response.data.erro) {
            this.address.address = response.data.logradouro;
            this.address.neighborhood = response.data.bairro;
            this.address.city = response.data.localidade;
            this.address.state = response.data.uf;
            this.address.postalCode = response.data.cep;
            //
            this.storeAddressState();
            //
            this.$refs.number.focus();
          } else {
            this.snackbar.color = "warning";
            this.snackbar.text = "CEP não encontrado";
            this.snackbar.show = true;
          }
          this.loadingCep = false;
        });
      }
    },

    ///////////////////////////////
    async getCoordinatesAddress() {
      // console.log("this.address = ", JSON.stringify(this.address));
      if (!this.address.number && !this.address.foreignerAd) return;

      // console.log("dateSincApp= ", this.dateSincApp);
      if (this.dateSincApp) return;

      if (this.myCompanyCountry != "BR") return; // prever para outros paises

      if (
        this.address.addressId &&
        this.numberOld == this.address.number &&
        !this.address.foreignerAd &&
        this.address.distance
      )
        return;
      //
      this.addressComplete = this.getAddressComplete(this.address);
      //
      const entity = this.entity;
      // console.log("entity = ", entity);
      //
      if (entity != "deliver" && entity != "login") {
        var origin = {
          lat: Number(this.$store.state.user.companyLat),
          lng: Number(this.$store.state.user.companyLng),
        };
      } else {
        var origin = {
          lat: Number(this.address.latitudeOrigin),
          lng: Number(this.address.longitudeOrigin),
        };
      }
      // console.log("origin = ", JSON.stringify(origin));

      var departureTime = new Date();
      departureTime.setDate(departureTime.getDate() + 1);
      departureTime.setHours(4); // 4 o'clock in the morning there is no traffic
      // console.log("departureTime=", departureTime);
      //
      await loader
        .load()
        .then((google) => {
          //
          // Geocoder /////////////////////////////////////////////////////////////
          var geocoder = new google.maps.Geocoder();
          geocoder.geocode(
            {
              address: this.addressComplete,
            },
            function (response, status) {
              if (status != "OK") {
                console.info("Err maps.Geocoder = ", status);
                return;
              }
              const destination = response[0].geometry.location; // Lat Lng
              // console.log("destination=", JSON.stringify(destination));
              //
              // DistanceMatrixService ///////////////////////////////////////////////
              if (entity === "Enterprise") {
                // sessionStorage
                sessionStorage.setItem(
                  "apiGeocoder",
                  JSON.stringify({ destination })
                );
              } else {
                const matrix = new google.maps.DistanceMatrixService();
                matrix.getDistanceMatrix(
                  {
                    origins: [origin],
                    destinations: [destination],
                    travelMode: "DRIVING", // car
                    unitSystem: 0, // meters
                    drivingOptions: {
                      departureTime: departureTime, // date from now.
                      trafficModel: "pessimistic", // optimistic ou pessimistic
                    },
                    avoidHighways: false,
                    avoidTolls: false,
                  },
                  function (response, status) {
                    // console.log("response =", JSON.stringify(response));
                    if (status != "OK") {
                      console.info("Err maps.DistanceMatrixService = ", status);
                      console.info("origin     = ", JSON.stringify(origin));
                      console.info("destination=", JSON.stringify(destination));
                      return;
                    }
                    // console.log("Distance=", JSON.stringify(response.rows[0]));
                    let distance = response.rows[0].elements[0].distance;
                    let duration = response.rows[0].elements[0].duration;

                    // sessionStorage
                    sessionStorage.setItem(
                      "apiGeocoder",
                      JSON.stringify({ destination, distance, duration })
                    );
                  }
                );
              }
            }
          );
        })
        .catch((err) => {
          console.info(err);
        });
    },

    /////////////////////////////////////////////////
    getAddressComplete(address) {
      //
      // console.log("addressNumberFirst = ", this.addressNumberFirst);

      var addressComplete = "";
      //
      if (address.address) {
        if (this.addressNumberFirst) {
          if (address.number) {
            addressComplete = address.number + ", " + address.address;
          } else {
            addressComplete = address.address;
          }
        } else {
          if (address.number) {
            addressComplete = address.address + ", " + address.number;
          } else {
            addressComplete = address.address;
          }
        }
        if (address.complement) {
          addressComplete = addressComplete + " " + address.complement;
        }
        if (address.neighborhood) {
          addressComplete = addressComplete + " - " + address.neighborhood;
        }
        if (!address.countryCode) {
          address.countryCode = "";
        }

        addressComplete =
          addressComplete.trim() +
          " - " +
          address.city +
          " - " +
          address.state +
          " - " +
          address.postalCode +
          " " +
          address.countryCode.trim();
      }
      //
      // console.log("addressComplete = ", addressComplete);
      return addressComplete;
    },

    //////////////////
    validateNumber() {
      // alert("this.address.number   " + this.address.number);
      this.address.number = this.address.number.toUpperCase();
      if (
        this.address.number == "S" ||
        this.address.number == "S/" ||
        this.address.number == "S/N"
      ) {
        return;
      }
      this.address.number = this.address.number.replace(/[^0-9]/g, "");
    },
    ////////////////
    clearAddrees() {
      this.address.cepConsult = "";
      this.address.address = "";
      this.address.number = "";
      this.address.complement = "";
      this.address.neighborhood = "";
      this.address.city = "";
      this.address.state = "";
      this.address.postalCode = "";
    },
    ///////////////////////
    updateSessionPrefer() {
      localStorage.setItem(
        this.groupUser + this.pageId + this.$store.state.reduced + "Prefer",
        JSON.stringify(this.prefer)
      );
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  STORE   ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    storeAddressState() {
      // grava a UF para validar a IE  // alert("state = " + state);
      const state = this.address.state;
      this.$store.commit("UPDATE_ADDRESS_STATE", state);
    },
    ////////////////
    checkAccess(access) {
      let accessId = 0;
      if (this.entity === "Enterprise") accessId = 130;
      if (this.entity === "Customer") accessId = 310;
      if (this.entity === "Supplier") accessId = 330;
      if (this.entity === "Employee") accessId = 350;
      //
      this.access = this.$store.state.user.access.find(
        (obj) => obj.accessId === accessId // this.entity + " Address"
      );
      // console.log("this.access = " + JSON.stringify(this.access));
      if (!this.access.view) this.$router.go(-1);
    },
  },
  ///////////
  created() {
    this.countries = countriesList(sessionStorage.locale);
    // console.log(this.countries);
    //
    // this.entityId = this.$route.params.entityId;
    this.entityId = Number(sessionStorage.entityId);
    // console.log("this.entityId = " + this.entityId);
    this.entityForeigner = this.$store.state.entityForeigner;
    this.address.foreignerAd = this.entityForeigner;
    this.groupUser = this.$store.state.user.groupUser;

    this.dateSincApp = sessionStorage.dateSincApp || null;
    if (this.dateSincApp == "null") this.dateSincApp = "";
    // console.log("dateSincApp= ", this.dateSincApp);

    // Setting
    this.pageId = sessionStorage.pageId;
    this.entity = sessionStorage.entity;
    //
    if (this.entity === "deliver" || this.entity === "login") {
      this.myCompanyCountry = this.address.countryCode;
      this.cepLabel = this.cepLabel + " *";
      this.address.addressType = "Home";
      if (this.myCompanyCountry == "BR") {
        this.address.countryCode = "";
      }
      // this.address.countryCode = "";
      // console.log("2 this.myCompanyCountry = " + this.myCompanyCountry);
      this.access.view = true;
      this.access.new = true;
      this.prefer.addressMoreInfo = true;
      //
      this.$store.commit("UPDATE_ENTITY_FOREIGNER", false);
      this.$store.commit("UPDATE_ACCESS_VIEW", false);
      this.$store.commit("UPDATE_ACCESS_EDIT", false);
    } else {
      this.myCompanyCountry = this.$store.state.user.companyCountry;

      if (this.$store.state.user.locale) {
        this.addressNumberFirst =
          this.$store.state.user.locale.addressNumberFirst;
      }

      if (
        sessionStorage.pageOrigin == "Deliver" ||
        this.$store.state.user.profile == "Deliver"
      ) {
        this.access.view = true;
        this.access.new = true;
        this.access.edit = true;
        this.access.delete = true; // for inactive
        this.prefer.addressMoreInfo = true;
      } else {
        this.checkAccess(null);
        // prefer
        this.prefer = JSON.parse(
          localStorage.getItem(
            this.groupUser + this.pageId + this.$store.state.reduced + "Prefer"
          )
        );
        // console.log("this.prefer = " + JSON.stringify(this.prefer));
      }
    }
    if (!this.prefer) {
      this.prefer.addressMoreInfo = false;
    }

    if (!this.address.foreignerAd) this.address.foreignerAd = false; // coming from order
    // this.myCompanyCountry = this.$store.state.user.companyCountry;

    if (!this.address.addressId && !this.address.addressType) {
      if (this.$store.state.personType === "Person") {
        this.address.addressType = "Home";
      } else {
        this.address.addressType = "Main";
      }
    }

    // api google
    sessionStorage.removeItem("apiGeocoder");
    // console.log("this.address.distance = ", this.address.distance);
    if (this.address.addressId && this.myCompanyCountry == "BR") {
      // não esta conseguindo obter de outros paises distantes ex.: US
      this.numberOld = this.address.number;
      if (
        !this.address.distance &&
        !this.address.foreignerAd &&
        !this.dateSincApp
      ) {
        // when change adrress_id main enterprise
        this.getCoordinatesAddress();
        this.alertNewGeolocation = true;
        this.address.update = true;
      }
    }
    //
    // console.log("0 this.address = ", JSON.stringify(this.address));
  },
};
</script>
